var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentBox"},[_c('vue-element-loading',{attrs:{"active":_vm.loadingActive,"is-full-screen":true,"color":"#2674fc","background-color":"rgba(0,0,0,.3)","text":"正在上传，请稍后！"}}),_c('section',[_c('el-card',{staticClass:"box-card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"cardTitle"},[_vm._v(_vm._s(_vm.editTitle))])]),_c('div',{staticClass:"formBox"},[_c('el-form',{ref:"comInfo",staticClass:"comForm",attrs:{"model":_vm.comInfo,"label-width":"80px"}},[_c('el-form-item',{staticClass:"avatarBox",attrs:{"label":"企业头像","rules":[
              {
                required: true,
                message: '请设置企业头像',
                trigger: 'change',
              } ]}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.uploadUrl,"headers":_vm.headers,"data":_vm.avatarData,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.imageUrl)?_c('img',{staticClass:"avatar mini",attrs:{"src":_vm.imageUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon mini"})]),_c('div',{staticClass:"avatarMsg"},[_c('p',[_vm._v("支持jpg、png格式的图片，大小限制1MB内")])])],1),_c('el-row',{attrs:{"gutter":16}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"企业名称","prop":"com_name","rules":[
                  {
                    required: true,
                    message: '请输入企业名称',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"disabled":!_vm.haveDataBtn},model:{value:(_vm.comInfo.com_name),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_name", $$v)},expression:"comInfo.com_name"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"对外名称","prop":"com_external_name","rules":[
                  {
                    required: true,
                    message: '请输入企业对外名称',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{model:{value:(_vm.comInfo.com_external_name),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_external_name", $$v)},expression:"comInfo.com_external_name"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"企业性质","prop":"com_nature","rules":[
                  {
                    required: true,
                    message: '请选择企业性质',
                    trigger: 'change',
                  } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.comInfo.com_nature),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_nature", $$v)},expression:"comInfo.com_nature"}},_vm._l((_vm.com_natureList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.label}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"企业规模","prop":"com_size","rules":[
                  {
                    required: true,
                    message: '请选择企业性质',
                    trigger: 'change',
                  } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.comInfo.com_size),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_size", $$v)},expression:"comInfo.com_size"}},_vm._l((_vm.com_sizeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.label}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"label":"公司网址","prop":"com_website"}},[_c('el-input',{model:{value:(_vm.comInfo.com_website),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_website", $$v)},expression:"comInfo.com_website"}})],1),_c('el-form-item',{staticClass:"licenseBox",attrs:{"label":"营业执照","rules":[
              {
                required: true,
                message: '请上传营业执照',
                trigger: 'change',
              } ]}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.uploadUrl,"headers":_vm.headers,"data":_vm.licenseData,"show-file-list":false,"on-success":_vm.handleAvatarSuccess2,"before-upload":_vm.beforeAvatarUpload2,"disabled":!_vm.haveDataBtn}},[(_vm.licenseUrl)?_c('img',{staticClass:"avatar langer",attrs:{"src":_vm.licenseUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon langer"})]),_c('div',{staticClass:"licenseMsg"},[_c('p',[_vm._v("温馨提示：")]),_c('p',[_vm._v(" 1、支持jpg、png格式的图片，最大不能超过 3MB，最小不低于1MB； ")]),_c('p',[_vm._v(" 2、营业执照扫描件（复印件）上的名称必须和填写的公司名称一致； ")]),_c('p',[_vm._v("3、上传清晰的营业执照更容易通过审核。")]),_c('p',[_vm._v("4、请不要提交虚假信息，我们对用户提交的信息严格保密。")])])],1),_c('el-form-item',{attrs:{"label":"业务范围","prop":"com_business","rules":[
              {
                required: true,
                message: '请选择业务范围',
                trigger: 'change',
              } ]}},[_c('el-checkbox-group',{on:{"change":_vm.changeBusList},model:{value:(_vm.busList),callback:function ($$v) {_vm.busList=$$v},expression:"busList"}},[_c('el-checkbox',{attrs:{"label":"兼职服务"}}),_c('el-checkbox',{attrs:{"label":"培训"}}),_c('el-checkbox',{attrs:{"label":"资质代办"}})],1)],1),(_vm.goodAtShow)?_c('el-form-item',{attrs:{"label":"代办范围","prop":"fixed_line","rules":[
              {
                required: true,
                message: '请选择代办范围',
                trigger: 'change',
              } ]}},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("全选")]),_c('el-checkbox-group',{on:{"change":_vm.handleCheckedCitiesChange},model:{value:(_vm.goodAtList),callback:function ($$v) {_vm.goodAtList=$$v},expression:"goodAtList"}},_vm._l((_vm.goodAtSel),function(item,index){return _c('el-checkbox',{key:index,attrs:{"label":item}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"公司介绍","prop":"com_synopsis","rules":[
              {
                required: true,
                message: '请输入公司介绍',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":3},model:{value:(_vm.comInfo.com_synopsis),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_synopsis", $$v)},expression:"comInfo.com_synopsis"}})],1),_c('el-form-item',{attrs:{"label":"企业固话","prop":"com_fixed"}},[_c('el-input',{model:{value:(_vm.comInfo.com_fixed),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_fixed", $$v)},expression:"comInfo.com_fixed"}})],1),_c('el-form-item',{attrs:{"label":"所在地区","prop":"u_social","rules":[
              {
                required: true,
                message: '请输入所在地区和街道门牌号',
                trigger: 'blur',
              } ]}},[_c('el-cascader',{staticStyle:{"width":"48.5%"},attrs:{"size":"large","options":_vm.comcity,"placeholder":_vm.addresInfo},on:{"change":_vm.setUseInfoSocial},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}}),_vm._v(" — "),_c('el-input',{staticStyle:{"width":"48.5%"},attrs:{"placeholder":"街道门牌号"},model:{value:(_vm.comInfo.com_address),callback:function ($$v) {_vm.$set(_vm.comInfo, "com_address", $$v)},expression:"comInfo.com_address"}})],1),_c('el-form-item',{staticClass:"text-center"},[_c('el-button',{directives:[{name:"prevent-repeat-click",rawName:"v-prevent-repeat-click"}],staticClass:"radius-25",staticStyle:{"width":"280px"},attrs:{"type":"primary"},on:{"click":_vm.infoPost}},[_vm._v(" 提交 ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }